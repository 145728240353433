import { defineStore } from 'pinia'
import vcmQuery from '../apollo/queries/pages/vcm.gql'

export const useVcmStore = defineStore('vcm', {
  state: () => ({
    data: {}
  }),
  getters: {
    getVCM: (state) => {
      return state.data.vcm ? state.data.vcm.data.attributes : null
    }
  },
  actions: {
    async requestData() {
      if (Object.keys(this.data).length !== 0) return

      const { data } = await useAsyncQuery(vcmQuery) // eslint-disable-line no-undef

      if (data) this.data = data
    }
  }
})
